<template>
  <v-container>
    <v-card max-width="700" class="my-10 mx-auto">
      <v-card-title> الأعدادت</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :rules="rules.require"
                v-model="sign_data.wallet_type"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  نوع المحفظة
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->
            <v-col cols="12" sm="6">
              <v-text-field
                :rules="rules.require"
                v-model="sign_data.wallet_number"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  قم المحفظة
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->
            <v-col cols="12" sm="6">
              <v-text-field
                :rules="rules.password"
                type="password"
                v-model="sign_data.password"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  كلمة المرور
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->
            <v-col cols="12" sm="6">
              <v-text-field
                :rules="rules.c_password"
                type="password"
                v-model="sign_data.c_password"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  تكرار كلمة المرور
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="save_loading"
          :disabled="save_loading"
          @click="sign_up()"
          color="success"
          >حفظ الأعدادت</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-snackbar width="auto" right v-model="snackbar">
      تم تحفظ البيانات بنجاح
      <template v-slot:action="{ attrs }">
        <v-icon color="success" v-bind="attrs">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "settings",
  data() {
    return {
      snackbar: false,
      dialog: false,
      save_loading: false,
      rules: {
        require: [(v) => !!v || "حقل مطلوب"],
        password: [
          (v) => !!v || "كلمة المرور مطلوب",
          (v) => !(v.toString().length < 8) || "يجب ان يكون 8 او كثر",
        ],
        c_password: [(v) => !!this.not_mutch || "غير متطابقة"],
      },
      sign_data: {
        password: "",
        c_password: "",
        wallet_number: "",
        wallet_type: "",
      },
    };
  },
  computed: {
    not_mutch() {
      return this.sign_data.c_password == this.sign_data.password;
    },
  },
  methods: {
    sign_up() {
      if (this.$refs.form.validate()) {
        this.save_loading = true;
        const formData = new FormData();
        Object.keys(this.sign_data).forEach((e, i) => {
          formData.append(e, this.sign_data[e]);
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: "admin_settings",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            // console.log(res);
            this.save_loading = false;
            this.snackbar = true;
            setTimeout((e) => {
              this.$router.push("/Dashboard");
            }, 1000);
          })
      }
    },
    get_setting() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "get_admin_settings",
            method: "get",
          },
        })
        .then((res) => {
          this.sign_data.wallet_number = res.data.data.wallet_number;
          this.sign_data.wallet_type = res.data.data.wallet_type;
        })
    },
  },
  mounted() {
    this.get_setting();
  },
};
</script>
